import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import Sidebar from './components/Navbar';
import Dashboard from './Pages/Dashboard';
import OKRRelationships from './Pages/OKRRelationship';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import MyOKRs from './Pages/MyOKRs';
import LoginPage from './LoginPage';
import PrivateRoute from './routes/PrivateRoute';
import SignUp from './Auth/Signup';
import ForgotPassword from './Auth/ForgotPassword';
import PasswordResetSuccess from './Auth/PasswordResetSuccess';
import ResetPassword from './Auth/ResetPassword';
import supabase from './Auth/supabase';
import TopBar from './components/TopBar';
import { Box } from '@mui/material';
import Layout from './Layout';
import SlackInstalled from './Auth/SlackInstalled';
import LandingPage from './landing/LandingPage';
import ContactUsPage from './landing/components/ContactUsPage';
import TermsPage from './landing/components/TermsPage';
import PrivacyPage from './landing/components/PrivacyPage';
import BlogPostEditor from './landing/components/blog/admin/BlogPostEditor';
import BlogAdmin from './landing/components/blog/admin/BlogAdmin';
import BlogList from './landing/components/blog/BlogList';
import BlogPost from './landing/components/blog/BlogPost';
import AdminLogin from './landing/components/blog/admin/AdminLogin';
import AdminRoute from './routes/AdminRoute';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [authCompleted, setAuthCompleted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
      setAuthCompleted(true);
    };

    checkSession();

    const {data: authListener} = supabase.auth.onAuthStateChange(async (event, session) => {
      setIsAuthenticated(!!session);
      setAuthCompleted(true);

      const publicPaths = [
        '/',
        '/contact-us',
        '/slack/oauth/callback', 
        '/slack/installed', 
        '/login',
        '/sign-up',
        '/forgot-password',
        '/reset-password',
        '/password-reset-success',
        '/admin/login',
        '/blog',
        '/privacy',
        '/terms',
      ];  
      
          // Check if current path is public or is a blog post URL
    const isPublicPath = publicPaths.includes(location.pathname) || location.pathname.startsWith('/blog/');
    
    if (!session && !isPublicPath) {
      navigate('/login');
    }
  });

    if (location.pathname === '/slack/installed') {
      const queryParams = new URLSearchParams(location.search);
    }

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [location, navigate]);

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem('authToken');
    navigate('/login');
    window.location.reload();
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Routes>
      {/* Landing page at root URL */}
        <Route path="/" element={<LandingPage />} />

        {/* Public Routes */}
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/slack/installed" element={<SlackInstalled />} />
        <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/auth/confirm" element={<Navigate to="/login" replace />} />

          {/* Admin routes */}
          <Route path="/admin/login" element={<AdminLogin />} />
      <Route element={<AdminRoute />}>
        <Route path="/admin/blog" element={<BlogAdmin />} />
        <Route path="/admin/blog/new" element={<BlogPostEditor />} />
        <Route path="/admin/blog/edit/:id" element={<BlogPostEditor />} />
        </Route>
        
        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout logout={logout} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/my-okrs" element={<MyOKRs />} />
            <Route path="/okr-relationships" element={<OKRRelationships />} />
          </Route>
        </Route>
        
        {/* Redirect all other routes to login */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
}

export default App;