// src/landing/components/sections/WhySection.tsx
import React from 'react';
import dashboardOverview from '../../assets/dashboard-overview.png';
import slackReminder1 from '../../assets/slack-reminder-1.png';
import slackReminder2 from '../../assets/slack-reminder-2.png';
import slackReminder3 from '../../assets/slack-reminder-3.png';
import slackReminder from '../../assets/slack-reminder.png';


const WhySection: React.FC = () => {
  return (
    <div className="tw-py-24 tw-bg-white">
      <div className="tw-max-w-7xl tw-mx-auto tw-px-6 lg:tw-px-8">
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-16">
          {/* Left Column */}
          <div>
            <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900">
              Get the overview of the company's performance you need
            </h2>
            <div className="tw-mt-8">
              {/* Large Screenshot Container */}
              <div className="tw-rounded-2xl tw-bg-gray-50 tw-p-4">
                <img
               src={dashboardOverview}
                alt="Dashboard Overview"
                  className="tw-w-full tw-rounded-xl tw-shadow-lg"
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div>
            <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900">
              And let Slack do the reminders for you. Automatically
            </h2>
            <div className="tw-mt-8 tw-space-y-6">
              {/* Single Screenshot Container */}
              <div className="tw-rounded-2xl tw-bg-gray-50 tw-p-4">
                <img
                  src={slackReminder}
                  alt="Slack Reminders"
                  className="tw-w-full tw-rounded-xl tw-shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySection;