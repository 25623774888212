// src/landing/LandingPage.tsx

import React from 'react';
import Hero from './components/sections/Hero';
import WhySection from './components/sections/WhySection';
import HowSection from './components/sections/HowSection';
import AudienceSection from './components/sections/AudienceSection';
import SignupSection from './components/sections/SignupSection';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import './styles/landing.css';  


const LandingPage: React.FC = () => {
  return (
    <div id="landing-page" className="tw-min-h-screen tw-bg-white">  {/* Added tw- prefix */}
      <Navigation />
      <Hero />
      <WhySection />
      <HowSection />
      <AudienceSection />
      <SignupSection />
      <Footer />
    </div>
  );
};

export default LandingPage;