import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../../../Auth/supabase';
import { BlogPost } from '../../types/blog';

const BlogList: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('is_published', true)
        .order('published_date', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="tw-text-center tw-py-12">Loading...</div>;
  }

  return (
    <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-12">
      <h1 className="tw-text-4xl tw-font-bold tw-text-gray-900 tw-mb-12">Blog</h1>
      <div className="tw-space-y-12">
        {posts.map((post) => (
          <article key={post.id} className="tw-border-b tw-border-gray-200 tw-pb-12">
            <Link 
              to={`/blog/${post.slug}`}
              className="tw-block group"
            >
              <h2 className="tw-text-2xl tw-font-bold tw-text-gray-900 group-hover:tw-text-indigo-600 tw-mb-4">
                {post.title}
              </h2>
              {post.excerpt && (
                <p className="tw-text-gray-600 tw-mb-4">
                  {post.excerpt}
                </p>
              )}
              <div className="tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                {post.author && (
                  <span className="tw-mr-4">By {post.author}</span>
                )}
                <time dateTime={post.published_date || post.created_at}>
                  {new Date(post.published_date || post.created_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </time>
              </div>
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
