import React, { useState, useEffect } from 'react';
import Tree, { RenderCustomNodeElementFn, RawNodeDatum } from 'react-d3-tree';
import supabase from '../Auth/supabase';
import { Box, Typography } from '@mui/material';
import theme from 'theme';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface TreeNode {
  name: string;
  _id?: string;
  ParentGoalId?: string;
  attributes?: {
    type: string;
    status: string;
    owner?: string;
    team?: string;
  };
  collapsed?: boolean;
  children?: TreeNode[];
}

function transformOKRs(okrs: OKR[]): TreeNode {
  const activeOKRs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  // Create lookup maps for each type of OKR
  const okrMap = new Map<string, TreeNode>();
  
  // First pass: Create nodes for all OKRs
  activeOKRs.forEach((okr) => {
    okrMap.set(okr._id, {
      name: okr.Goal,
      _id: okr._id,
      ParentGoalId: okr.ParentGoalId,
      children: [],
      attributes: {
        type: okr.GoalType,
        status: okr.Status,
        owner: okr.AssigneeName,
        team: okr.Team,
      },
      collapsed: false,
    });
  });

  // Second pass: Build the hierarchy
  const rootChildren: TreeNode[] = [];
  activeOKRs.forEach((okr) => {
    const node = okrMap.get(okr._id);
    if (!node) return;

    if (okr.ParentGoalId && okrMap.has(okr.ParentGoalId)) {
      // This OKR has a parent, add it to parent's children
      const parentNode = okrMap.get(okr.ParentGoalId);
      if (parentNode && parentNode.children) {
        parentNode.children.push(node);
      }
    } else if (okr.GoalType === 'Company') {
      // Company OKRs with no parent go to root
      rootChildren.push(node);
    }
  });

  // Create root node
  const rootNode: TreeNode = {
    name: 'All OKRs',
    _id: 'root',
    children: rootChildren,
    attributes: {
      type: 'Root',
      status: '',
      owner: '',
      team: '',
    },
    collapsed: false,
  };

  return rootNode;
}

function OKRRelationships() {
  const [okrs, setOkrs] = useState<TreeNode | null>(null);
  const [okrDataById, setOkrDataById] = useState<Map<string, OKR>>(new Map());
  const [error, setError] = useState<string | null>(null);
  const [selectedNodes, setSelectedNodes] = useState<{ [key: string]: boolean }>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOKR, setSelectedOKR] = useState<OKR | null>(null);

  const fetchOKRs = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const response = await fetch(`${apiBaseUrl}/api/okrs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        // Create a mapping from OKR IDs to OKR data
        const okrDataMap = new Map<string, OKR>();
        data.forEach((okr: OKR) => {
          okrDataMap.set(okr._id, okr);
        });
        setOkrDataById(okrDataMap);

        const treeData = transformOKRs(data);
        setOkrs(treeData);
      } else {
        console.error('No auth token found');
        setError('No auth token found');
      }
    } catch (error) {
      console.error('Failed to fetch OKRs:', error);
      setError('Failed to fetch OKRs');
    }
  };

  useEffect(() => {
    fetchOKRs();
  }, []);

  const handleNodeClick = (nodeDatum: RawNodeDatum) => {
    const customNode = nodeDatum as TreeNode;

    if (customNode._id && customNode._id !== 'root') {
      const okr = okrDataById.get(customNode._id);
      if (okr) {
        setSelectedOKR(okr);
        setOpenModal(true);
      }
    }

    // Existing code to handle selected nodes (if any)
    const newSelectedNodes: { [key: string]: boolean } = {};
    if (customNode.attributes?.type === 'Company') {
      newSelectedNodes[customNode._id || ''] = true;
    } else if (customNode.attributes?.type === 'Team') {
      newSelectedNodes[customNode._id || ''] = true;
      if (customNode.ParentGoalId) {
        newSelectedNodes[customNode.ParentGoalId] = true;
      }
    }
    setSelectedNodes(newSelectedNodes);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOKR(null);
  };

  const handleSaveOKR = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token && selectedOKR) {
        const { _id, ...okrData } = selectedOKR;
        const updateData = {
          Goal: okrData.Goal,
          AssigneeName: okrData.AssigneeName,
          Status: okrData.Status,
          TimePeriod: okrData.TimePeriod,
          Comments: okrData.Comments,
        };

        await axios.put(
          `${apiBaseUrl}/api/okrs/${_id}`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Refresh OKRs after update
        await fetchOKRs();
        handleCloseModal();
      }
    } catch (error) {
      console.error('Failed to update OKR:', error);
      setError('Failed to update OKR');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  const renderNode: RenderCustomNodeElementFn = ({ nodeDatum }) => {
    const customNode = nodeDatum as TreeNode;
    const { type = 'Root', status = '', owner = '', team = '' } =
      customNode.attributes || {};

    const statusColors: { [key: string]: string } = {
      Green: '#4caf50',
      Amber: '#ff9800',
      Red: '#f44336',
    };

    const getBorderColor = () => {
      if (selectedNodes[customNode._id || '']) return '#f44336';
      switch (type) {
        case 'Company': return theme.palette.primary.main;
        case 'Team': return theme.palette.success.main;
        default: return theme.palette.grey[400];
      }
    };
  
    return (
      <g className="tree-node" onClick={() => handleNodeClick(customNode)}>
        {/* Card Background */}
        <rect
          x={-130}
          y={-80}
          width="260"
          height="130"
          fill="white"
          stroke={getBorderColor()}
          strokeWidth="2"
          rx="10"
          ry="10"
          filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1))"
          />
  
        {/* Goal Title */}
        <foreignObject x={-120} y={-70} width={240} height={60}>
          <div style={{
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            lineHeight: '1.4em',
            padding: '0 8px'
          }}>
            {customNode.name}
          </div>
        </foreignObject>
  
        {/* Team Node Details */}
        {(type === 'Team' || type === 'Personal') && (
        <>
          {/* Team details - Updated typography */}
          <foreignObject x={-110} y={-20} width={190} height={20}>
            <Typography variant="body2" color="text.secondary">
              Owner: {owner}
            </Typography>
          </foreignObject>

          <foreignObject x={-110} y={0} width={190} height={20}>
            <Typography variant="body2" color="text.secondary">
              Team: {team}
            </Typography>
          </foreignObject>

          <foreignObject x={-110} y={20} width={190} height={20}>
            <Typography variant="body2" color="text.secondary">
              Status:
            </Typography>
          </foreignObject>
          <circle
            cx={-55}
            cy={30}
            r="8"
            fill={statusColors[status] || '#000000'}
          />
        </>
      )}
    </g>
  );
};

  const customPathFunc = (linkDatum: {
    source: { x: number; y: number };
    target: { x: number; y: number };
  }) => {
    const { source, target } = linkDatum;
    const path = `
      M${source.x},${source.y + 25}
      L${source.x},${(source.y + target.y) / 2}
      L${target.x},${(source.y + target.y) / 2}
      L${target.x},${target.y - 25}
    `;
    return path;
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              border: `2px solid ${theme.palette.primary.main}`,
              mr: 1,
              borderRadius: '4px',
            }}
          ></Box>
          <Typography>Company Goals</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              border: `2px solid ${theme.palette.success.main}`,
              mr: 1,
              borderRadius: '4px',
            }}
          ></Box>
          <Typography>Team Goals</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
    <Box
      sx={{
        width: 20,
        height: 20,
        border: `2px solid ${theme.palette.grey[400]}`,
        mr: 1,
        borderRadius: '4px',
      }}
    ></Box>
    <Typography>Personal Goals</Typography>
  </Box>
      </Box>
      <div
        style={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {okrs ? (
          <Tree
          data={okrs}
          orientation="vertical"
          pathFunc={customPathFunc}
          translate={{ x: window.innerWidth / 2.5, y: 1 }}
          separation={{ siblings: 2, nonSiblings: 2 }}
          collapsible={true}
          zoomable={false}
          renderCustomNodeElement={renderNode}
          nodeSize={{ x: 200, y: 200 }}
          />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </div>

      {/* Modal for OKR details */}
      {selectedOKR && (
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit OKR</DialogTitle>
        <DialogContent>
            <TextField
              label="Goal"
              name="Goal"
              value={selectedOKR.Goal}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Goal: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedOKR.Status || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Status: e.target.value as string,
                  })
                }
                label="Status"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Amber">Amber</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Deadline"
              name="TimePeriod"
              type="date"
              value={
                selectedOKR.TimePeriod
                  ? new Date(selectedOKR.TimePeriod).toISOString().split('T')[0]
                  : ''
              }
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  TimePeriod: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Comments"
              name="Comments"
              value={selectedOKR.Comments || ''}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Comments: e.target.value,
                })
              }
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              onClick={handleSaveOKR}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default OKRRelationships;