// src/landing/components/sections/SignupSection.tsx
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link if you want to use router navigation

const SignupSection: React.FC = () => {
  return (
    <div className="tw-bg-indigo-700">
      <div className="tw-max-w-7xl tw-mx-auto tw-py-12 tw-px-4 tw-sm:px-6 tw-lg:py-16 tw-lg:px-8 tw-lg:flex tw-lg:items-center tw-lg:justify-between">
        <h2 className="tw-text-3xl tw-font-extrabold tw-tracking-tight tw-text-white tw-sm:text-4xl">
          <span className="tw-block">Ready to dive in?</span>
          <span className="tw-block tw-text-indigo-200">Sign up for early access today.</span>
        </h2>
        <div className="tw-mt-8 tw-flex tw-lg:mt-0 tw-lg:flex-shrink-0">
          <div className="tw-inline-flex tw-rounded-md tw-shadow">
            <Link  // Using Link instead of anchor tag
            to="/contact-us"
            className="tw-inline-flex tw-items-center tw-justify-center tw-px-5 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-indigo-600 tw-bg-white hover:tw-bg-indigo-50"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupSection;