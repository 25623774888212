import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../../../../Auth/supabase';
import { BlogPost } from '../../../types/blog';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';


const BlogAdmin: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      localStorage.removeItem('isAdmin');
      navigate('/admin/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  
  const togglePublishStatus = async (post: BlogPost) => {
    try {
      const { error } = await supabase
        .from('blog_posts')
        .update({ is_published: !post.is_published })
        .eq('id', post.id);

      if (error) throw error;
      await fetchPosts();
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  return (
    <div className="tw-max-w-7xl tw-mx-auto tw-px-4 tw-py-8">
      {/* Header with Logout */}
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
        <div className="tw-flex tw-items-center tw-gap-4">
          <h1 className="tw-text-3xl tw-font-bold tw-text-gray-900">Blog Posts</h1>
          <Button
            onClick={() => navigate('/admin/blog/new')}
            variant="contained"
            color="primary"
            size="small"
          >
            New Post
          </Button>
        </div>
        <Button
          onClick={handleLogout}
          variant="outlined"
          color="error"
          startIcon={<LogoutIcon />}
          size="small"
        >
          Logout
        </Button>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden">
          <table className="tw-min-w-full tw-divide-y tw-divide-gray-200">
            <thead className="tw-bg-gray-50">
              <tr>
                <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Title
                </th>
                <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Status
                </th>
                <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Date
                </th>
                <th className="tw-px-6 tw-py-3 tw-text-right tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
              {posts.map((post) => (
                <tr key={post.id}>
                  <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                    <div className="tw-text-sm tw-font-medium tw-text-gray-900">{post.title}</div>
                  </td>
                  <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                    <span
                      className={`tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full ${
                        post.is_published
                          ? 'tw-bg-green-100 tw-text-green-800'
                          : 'tw-bg-yellow-100 tw-text-yellow-800'
                      }`}
                    >
                      {post.is_published ? 'Published' : 'Draft'}
                    </span>
                  </td>
                  <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                    <div className="tw-text-sm tw-text-gray-500">
                      {new Date(post.created_at).toLocaleDateString()}
                    </div>
                  </td>
                  <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-sm tw-font-medium">
                    <Button
                      onClick={() => navigate(`/admin/blog/edit/${post.id}`)}
                      color="primary"
                      size="small"
                      sx={{ mr: 2 }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => togglePublishStatus(post)}
                      color="secondary"
                      size="small"
                    >
                      {post.is_published ? 'Unpublish' : 'Publish'}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BlogAdmin;

