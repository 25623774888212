// src/landing/components/Navigation.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';

const Navigation: React.FC = () => {
    return (
      <nav className="tw-fixed tw-w-full tw-top-0 tw-z-50 tw-bg-white">
        <div className="tw-max-w-7xl tw-mx-auto tw-px-6 tw-py-4 tw-flex tw-items-center tw-justify-between">
          <Link to="/" className="tw-flex tw-items-center">
            <img src={logo} alt="OnTrakk Logo" className="tw-h-8 tw-mr-2" />
          </Link>
          <div className="tw-flex tw-items-center tw-space-x-4">
            <Link 
              to="/login" 
              className="tw-text-gray-600 hover:tw-text-gray-900"
            >
              Login
            </Link>
            <Link 
              to="/contact-us"
              className="tw-bg-indigo-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-md hover:tw-bg-indigo-700"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </nav>
    );
  };
  
  export default Navigation;
  