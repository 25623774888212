// src/landing/components/sections/Hero.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const OKRAnimation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 200" className="tw-w-full tw-max-w-2xl tw-mx-auto tw-mt-6">
    {/* Task Circles with Labels */}
    <g transform="translate(100, 100)">
      {/* Task 1 */}
      <g>
        <circle cx="0" cy="0" r="35" fill="white" filter="url(#shadow)" />
        <circle cx="0" cy="0" r="35" fill="none" stroke="#4F46E5" strokeWidth="3">
          <animate attributeName="stroke-dasharray" values="0 220;220 0" dur="1s" fill="freeze" />
        </circle>
        <path className="checkmark" d="M -15 0 L -5 10 L 15 -10" 
              stroke="#4F46E5" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <animate attributeName="stroke-dasharray" values="0 50;50 0" dur="0.3s" begin="0.7s" fill="freeze" />
        </path>
        <text x="0" y="60" textAnchor="middle" fontFamily="Arial" fontSize="14" fill="#6B7280">Set</text>
      </g>

      {/* Task 2 */}
      <g transform="translate(150, 0)">
        <circle cx="0" cy="0" r="35" fill="white" filter="url(#shadow)" />
        <circle cx="0" cy="0" r="35" fill="none" stroke="#4F46E5" strokeWidth="3">
          <animate attributeName="stroke-dasharray" values="0 220;220 0" dur="1s" begin="1s" fill="freeze" />
        </circle>
        <path className="checkmark" d="M -15 0 L -5 10 L 15 -10" 
              stroke="#4F46E5" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <animate attributeName="stroke-dasharray" values="0 50;50 0" dur="0.3s" begin="1.7s" fill="freeze" />
        </path>
        <text x="0" y="60" textAnchor="middle" fontFamily="Arial" fontSize="14" fill="#6B7280">Track</text>
      </g>

      {/* Task 3 */}
      <g transform="translate(300, 0)">
        <circle cx="0" cy="0" r="35" fill="white" filter="url(#shadow)" />
        <circle cx="0" cy="0" r="35" fill="none" stroke="#4F46E5" strokeWidth="3">
          <animate attributeName="stroke-dasharray" values="0 220;220 0" dur="1s" begin="2s" fill="freeze" />
        </circle>
        <path className="checkmark" d="M -15 0 L -5 10 L 15 -10" 
              stroke="#4F46E5" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <animate attributeName="stroke-dasharray" values="0 50;50 0" dur="0.3s" begin="2.7s" fill="freeze" />
        </path>
        <text x="0" y="60" textAnchor="middle" fontFamily="Arial" fontSize="14" fill="#6B7280">Achieve</text>
      </g>
    </g>

    {/* Connection lines */}
    <g transform="translate(100, 100)" stroke="#E5E7EB" strokeWidth="2">
      <line x1="35" y1="0" x2="115" y2="0">
        <animate attributeName="stroke" values="#E5E7EB;#4F46E5" dur="0.3s" begin="1s" fill="freeze" />
      </line>
      <line x1="185" y1="0" x2="265" y2="0">
        <animate attributeName="stroke" values="#E5E7EB;#4F46E5" dur="0.3s" begin="2s" fill="freeze" />
      </line>
    </g>

    {/* Filters */}
    <defs>
      <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
        <feDropShadow dx="0" dy="4" stdDeviation="4" floodOpacity="0.05"/>
      </filter>
    </defs>
  </svg>
);

const Hero: React.FC = () => {
  return (
    <div className="tw-relative tw-pt-20">
      <div className="tw-max-w-7xl tw-mx-auto tw-px-6 tw-py-16 sm:tw-py-24">
        <div className="tw-text-center">
          <h1 className="tw-text-6xl tw-font-bold tw-tracking-tight">
            The power of OKRs{' '}
            <span className="tw-text-indigo-600">
              without{' '}
              <br className="tw-hidden sm:tw-block" />
              the organisational burden
            </span>
          </h1>
          <p className="tw-mt-6 tw-text-xl tw-text-gray-600 tw-max-w-2xl tw-mx-auto">
            Enables you to successfully implement and manage OKRs in your existing workflow.
          </p>
          <div className="tw-mt-10 tw-flex tw-justify-center tw-gap-x-6">
            <Link
              to="/contact-us"
              className="tw-rounded-md tw-bg-indigo-600 tw-px-3.5 tw-py-2.5 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600"
            >
              Get Started
            </Link>
          </div>
          <OKRAnimation />
        </div>
      </div>
    </div>
  );
};

export default Hero;