// src/components/blog/admin/BlogPostEditor.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import supabase from '../../../../Auth/supabase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface BlogPostForm {
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  author: string;
}

const BlogPostEditor: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<BlogPostForm>({
    title: '',
    slug: '',
    content: '',
    excerpt: '',
    author: '',
  });

  useEffect(() => {
    if (id) {
      fetchPost();
    }
  }, [id]);

  const fetchPost = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (data) {
        setForm({
          title: data.title,
          slug: data.slug,
          content: data.content,
          excerpt: data.excerpt || '',
          author: data.author || '',
        });
      }
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
        const currentDate = new Date().toISOString();

      if (id) {
        // Update existing post
        const { error } = await supabase
          .from('blog_posts')
          .update({
            ...form,
            updated_at: currentDate,
          })
          .eq('id', id);

        if (error) throw error;
      } else {
        // Create new post
        const { error } = await supabase.from('blog_posts').insert([
          {
            ...form,
            is_published: false,
            created_at: currentDate,
            updated_at: currentDate,
            published_date: null  // This will be set when post is published
          },
        ]);

        if (error) throw error;
      }

      navigate('/admin/blog');
    } catch (error) {
      console.error('Error saving post:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-8">
      <h1 className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-mb-8">
        {id ? 'Edit Post' : 'New Post'}
      </h1>

      <form onSubmit={handleSubmit} className="tw-space-y-6">
        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Title</label>
          <input
            type="text"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
            required
          />
        </div>

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Slug</label>
          <input
            type="text"
            value={form.slug}
            onChange={(e) => setForm({ ...form, slug: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
            required
          />
        </div>

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Content</label>
          <ReactQuill
            theme="snow"
            value={form.content}
            onChange={(content) => setForm({ ...form, content })}
            className="tw-mt-1"
          />
        </div>

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Excerpt</label>
          <textarea
            value={form.excerpt}
            onChange={(e) => setForm({ ...form, excerpt: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
            rows={3}
          />
        </div>

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Author</label>
          <input
            type="text"
            value={form.author}
            onChange={(e) => setForm({ ...form, author: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
          />
        </div>

        <div className="tw-flex tw-justify-end tw-gap-4">
          <button
            type="button"
            onClick={() => navigate('/admin/blog')}
            className="tw-bg-white tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="tw-bg-indigo-600 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-py-2 tw-px-4 tw-inline-flex tw-justify-center tw-text-sm tw-font-medium tw-text-white hover:tw-bg-indigo-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlogPostEditor;