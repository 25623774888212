import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from '../../../Auth/supabase';
import { BlogPost as BlogPostType } from '../../types/blog';

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [post, setPost] = useState<BlogPostType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPost();
  }, [slug]);

  const fetchPost = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('slug', slug)
        .eq('is_published', true)
        .single();

      if (error) throw error;
      if (!data) {
        navigate('/blog');
        return;
      }
      
      setPost(data);
    } catch (error) {
      console.error('Error fetching post:', error);
      navigate('/blog');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="tw-text-center tw-py-12">Loading...</div>;
  }

  if (!post) {
    return null;
  }

  return (
    <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-12">
      <article>
        <h1 className="tw-text-4xl tw-font-bold tw-text-gray-900 tw-mb-6">
          {post.title}
        </h1>
        
        <div className="tw-flex tw-items-center tw-text-sm tw-text-gray-500 tw-mb-8">
          {post.author && (
            <span className="tw-mr-4">By {post.author}</span>
          )}
          <time dateTime={post.published_date || post.created_at}>
            {new Date(post.published_date || post.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </time>
        </div>

        <div 
          className="tw-prose tw-prose-indigo tw-max-w-none"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </article>
    </div>
  );
};

export default BlogPost;