// src/landing/components/TermsPage.tsx
import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const TermsPage: React.FC = () => {
    const navigate = useNavigate();

    return (

      <Container maxWidth="lg">

        <Box sx={{ py: 4 }}>
        {/* Back Button */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/')}
          sx={{
            mb: 4,
            color: 'text.secondary',
            '&:hover': {
              bgcolor: 'grey.100',
            },
          }}
        >
          Back to OnTrakk
        </Button>

        <Typography variant="h3" component="h1" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: January 11, 2025
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to OnTrakk ("we," "our," or "us"). By using our service, you agree to these Terms and Conditions.
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          2. Service Description
        </Typography>
        <Typography variant="body1" paragraph>
          OnTrakk provides OKR (Objectives and Key Results) management services through Slack integration, allowing users to set, track, and manage organizational goals.
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          3. User Accounts and Access
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          3.1 Slack Integration
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>You must have a valid Slack account to use our services. You are responsible for maintaining the security of your Slack credentials.</li>
            <li>You must be authorized to integrate OnTrakk with your organization's Slack workspace.</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          3.2 Account Requirements
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>You must provide accurate and complete information when registering. You are responsible for all activities under your account.</li>
            <li>You must notify us immediately of any unauthorized access.</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          4. User Data and Content
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          4.1 Your Content
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>You retain ownership of your content and data. You grant us license to use your content to provide our services.</li>
            <li>You are responsible for the legality of your content.</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          4.2 Data Storage
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>We use Supabase and MongoDB for secure data storage. Supabase is used for authentication and user management. MongoDB is used for storing OKR data and operational information.</li>
            <li>Your data is stored in accordance with our Privacy Policy.</li>
            <li>We implement reasonable security measures to protect your data.</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          5. Service Limitations
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>We do not guarantee uninterrupted service. We may modify or discontinue features with notice.</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          6. Acceptable Use
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- Violate any laws or regulations</li>
            <li>- Interfere with service operations</li>
            <li>- Share access to your account</li>
            <li>- Use the service for unauthorized purposes</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          7. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend access to our service immediately if:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- You breach these Terms</li>
            <li> - Your Slack workspace access is revoked</li>
            <li> - Payment obligations are not met</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          8. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We may modify these terms with notice through:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- Email notification</li>
            <li>- Service announcement</li>
            <li>- Website posting</li>
          </ul>
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsPage;