import React, { useState } from 'react';
import supabase from 'Auth/supabase';

const ContactUsPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      // Save to Supabase
      const { error: supabaseError } = await supabase
        .from('contact_us')
        .insert({ email });

      if (supabaseError) throw supabaseError;

      // Submit to Formspree
      const response = await fetch('https://formspree.io/f/xzzzkvez', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setEmail('');
        setSubmitted(true);
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };  

  return (
    <div className="tw-bg-white tw-py-24 tw-px-6 sm:tw-py-32 sm:tw-px-6 lg:tw-px-8">
      <div className="tw-max-w-2xl tw-mx-auto tw-text-center">
        {submitted ? (
          <>
            <h1 className="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-6xl">
              Thanks for reaching out!
            </h1>
            <p className="tw-mt-6 tw-text-lg tw-leading-8 tw-text-gray-600">
              We'll be in touch with you shortly.
            </p>
          </>
        ) : (
          <>
            <h1 className="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-6xl">
              Contact Us
            </h1>
            <p className="tw-mt-6 tw-text-lg tw-leading-8 tw-text-gray-600">
              Get the support you need to start seamlessly managing your OKRs today
            </p>
            <form onSubmit={handleSubmit} className="tw-mt-10 tw-space-y-6">
              <div>
                <label htmlFor="email" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                  Email address
                </label>
                <div className="tw-mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="tw-rounded-md tw-bg-indigo-600 tw-px-3.5 tw-py-2.5 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600 disabled:tw-opacity-50"
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUsPage;