import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import supabase from '../Auth/supabase';

const PrivateRoute: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error checking session:', error);
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(!!session);
      }

      setLoading(false);
    };

    checkSession();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const publicPaths = [
    '/',  // Landing page
    '/slack/oauth/callback',
    '/slack/installed'
  ];

  // Check if current path is in public paths
  if (!isAuthenticated && !publicPaths.includes(location.pathname)) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
