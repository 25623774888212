import React from 'react';

const FeatureItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <li className="tw-flex tw-items-start tw-p-4 tw-bg-white tw-rounded-xl tw-shadow-sm tw-transition-all tw-duration-300 hover:tw-shadow-md hover:tw-transform hover:tw-translate-y-[-2px]">
    <span className="tw-h-6 tw-w-6 tw-rounded-full tw-bg-indigo-500 tw-text-white tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
      ✓
    </span>
    <span className="tw-text-gray-600">{children}</span>
  </li>
);

const AudienceSection: React.FC = () => {
  const managerFeatures = [
    "No more chasing employees to update their OKRs - reminders are sent automatically",
    "Clearly see how each team is performing against their goals",
    "Celebrate your high performers - ongoing recognition of achievement of goals"
  ];

  const employeeFeatures = [
    "No need to login to a new software solution or find the latest version of the Google sheets - update directly from slack in a minute!",
    "See how your work is contributing to the overall company goals",
    "No need to remember to highlight your achievements at an annual performance review - clear visualisation of you achieving your goals"
  ];

  return (
    <div className="tw-py-24 tw-bg-gradient-to-b tw-from-white tw-to-gray-50">
      <div className="tw-max-w-7xl tw-mx-auto tw-px-6 lg:tw-px-8">
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-16">
          {/* For Managers Section */}
          <div className="tw-space-y-8">
            <div className="tw-space-y-4">
              <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900">
                For Managers
              </h2>
              <div className="tw-w-20 tw-h-1 tw-bg-indigo-500 tw-rounded"></div>
            </div>
            <ul className="tw-space-y-4">
              {managerFeatures.map((feature, index) => (
                <FeatureItem key={index}>{feature}</FeatureItem>
              ))}
            </ul>
          </div>

          {/* For Employees Section */}
          <div className="tw-space-y-8">
            <div className="tw-space-y-4">
              <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900">
                For Employees
              </h2>
              <div className="tw-w-20 tw-h-1 tw-bg-indigo-500 tw-rounded"></div>
            </div>
            <ul className="tw-space-y-4">
              {employeeFeatures.map((feature, index) => (
                <FeatureItem key={index}>{feature}</FeatureItem>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudienceSection;